import axios from "axios";
import { baseUrl } from "../api/baseUrls";
import { headersUser } from "../api/instance";

export const handleGetToken = async ({ id }, funcAfterRequest) => {
  const resp = await axios.post(`${baseUrl}/auth/get-access-token?user_id=${id}`, null, headersUser());
  const token = resp?.data?.response?.tokenData?.access_token;
  if (token) {
    localStorage.setItem("accessToken", token)
  }
  funcAfterRequest && funcAfterRequest()
}
