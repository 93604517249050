
export const headers = () => {
  const token = localStorage.getItem("accessToken");
  const lw_client = localStorage.getItem("lw_client");

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Lw-Client": lw_client
    }
  };
}

export const headersUser = () => {
  const token = localStorage.getItem("token");

  return { headers: { Authorization: `Bearer ${token}` } }
}